import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import getTranslateData from "../config/translation-data";

@Injectable()
export class TranslateService {
	data: any = {};

	constructor(private readonly http: HttpClient) { }

	use(lang: string): Promise<{}> {
		return new Promise<{}>((resolve, reject) => {
			this.data = getTranslateData(lang);

			resolve(this.data);
		});
	}
	getText(key: string, values?: any) {
		const keyArr = key.split(".");
		if (!keyArr[1]) {
			return this.data[keyArr[0]] ? (values ? this.replacePlaceholders(this.data[keyArr[0]], values) : this.data[keyArr[0]]) : key;
		}
		return this.data[keyArr[0]] && this.data[keyArr[0]][keyArr[1]] ?
			(values ? this.replacePlaceholders(this.data[keyArr[0]][keyArr[1]], values) : this.data[keyArr[0]][keyArr[1]]) : key;
	}

	replacePlaceholders(string: string, values) {
		return string.replace(
			/{{(.*?)}}/g,
			(placeholder, placeholderText) => values[placeholderText?.trim()] || placeholder
		);
	}
}
