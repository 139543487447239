import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { map } from "rxjs";
import { AuthService } from "../services/auth.service";
import { PermissionService } from "../services/permission.service";

@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(
		private readonly authService: AuthService,
		private readonly permission: PermissionService
	) { }
	public canActivate(route: ActivatedRouteSnapshot) {
		if (!route.data || !route.data['auth']) {
			return false;
		}
		if (this.authService.getUser()) {
			if (route.data['auth'] instanceof Array) {
				let found = false;
				for (const auth of route.data['auth']) {
					found = route.data['type'] == 'categoty' ? this.permission.hasPermissionCategory(auth) : this.permission.hasPermission(auth);
					if (found) {
						break;
					}
				}
				return found;
			} else {
				return route.data['type'] == 'categoty' ? this.permission.hasPermissionCategory(route.data['auth']) : this.permission.hasPermission(route.data['auth']);
			}
		} else {
			return this.authService.userDataChanged$.pipe(map(user => {
				if (route.data['auth'] instanceof Array) {
					let found = false;
					for (const auth of route.data['auth']) {
						found = route.data['type'] == 'categoty' ? this.permission.hasPermissionCategory(auth) : this.permission.hasPermission(auth);
						if (found) {
							break;
						}
					}
					return found;
				} else {
					return route.data['type'] == 'categoty' ? this.permission.hasPermissionCategory(route.data['auth']) : this.permission.hasPermission(route.data['auth']);
				}
			}))
		}
	}
}
