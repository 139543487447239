import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import Logger from "src/app/modules/core/services/logger-service/logger.service";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { TranslateService } from "../services/translate.service";
import { Router } from "@angular/router";

@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private readonly toaster: ToastrService, private readonly translateService: TranslateService,
		private readonly router: Router) { }

	handleError(error: HttpErrorResponse) {
		let msg = this.translateService.getText("COMMON.ERROR_MESSAGE:GENERIC");
		if (error.error && error.error.statusCode >= 700 && error.error.statusCode < 800) {
			msg = error.error.message;
		}
		if (error.status == 401 && error.error.message == "Insufficient privilege!") {
			msg = error.error.message;
		}
		if (error.status == 500 && (error.error.error || error.error.errorMessage)) {
			if (error.error.errorcode) {
				msg = error.error.errorMessage;
				// this.toaster.error(msg);
			} else
				msg = error.error.error;
		}

		if (error.status == 401 && error.error.message == "Unauthorized") {
			setTimeout(() => {
				this.router.navigate(["/logout"]);
			}, 1000);
		}
		return throwError(error);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Logger.info("Error interceptor" + request);
		return next.handle(request)
			.pipe(catchError(e => this.handleError(e)));
	}
}
