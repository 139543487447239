import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CoreService } from "src/app/modules/core/services/core.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IPaginatedData } from "src/app/modules/core/interfaces/pagination/pagination.interface";
import { environment } from "src/environments/environment";
import { shareReplay, tap } from "rxjs/operators";
import { ENDPOINTS as COMMONENDPOINTS } from "src/app/modules/admin/shared/endpoint-common";
import { ITenant } from 'src/app/modules/core/interfaces/tenant/tenant.interface';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
	ruleServiceUrl: string;
	configurationServiceUrl: string;
	constructor(private readonly http: HttpClient, private readonly coreService: CoreService) {
		this.ruleServiceUrl = this.coreService.getRulesServiceUrl();
		this.configurationServiceUrl = this.coreService.getConfigurationServiceUrl();
	}

	getAllTENANTs(pageParams): Observable<any> {
		const { page, size } = pageParams;
		let params = new HttpParams();//.append("page", page).append("size", size);
		const tenantlist$ = this.http.get<IPaginatedData>(COMMONENDPOINTS.tenant_summary.url, { params }).pipe(shareReplay());
		/* istanbul ignore else*/
		if (environment.mock) {
			tenantlist$.subscribe(console.log);
			return tenantlist$.pipe(
				tap((el: any) => {
					this.filterMockValues(pageParams, el, page, size);
				}));
		}
		return tenantlist$;
	}

	filterMockValues(pageParams, el, page, size) {
		const startIndex = page * (size - 1);
		const endIndex = startIndex + size - 1;
		if (pageParams && pageParams.searchText) {
			el.content = el.content?.filter(el =>
				el.name.toLowerCase().includes(pageParams.searchText.toLowerCase().trim()) ||
				el.displayName.toLowerCase().includes(pageParams.searchText.toLowerCase().trim()) ||
				el.aggregationType.toLowerCase().includes(pageParams.searchText.toLowerCase().trim()) ||
				el.unit.toLowerCase().includes(pageParams.searchText.toLowerCase().trim())
			);
			/* istanbul ignore else*/
			if (el.content?.length > 10) {
				el.content = el.content?.filter((_, index) => (index >= startIndex && index <= endIndex) || false);
				el.pageReference = { ...el.pageReference, size, number: page };
				return;
			}
			el.pageReference = { ...el.pageReference, size, number: 0 };
		} else {
			el.content = el.content?.filter((_, index) => (index >= startIndex && index <= endIndex) || false);
			el.pageReference = { ...el.pageReference, size, number: page };
		}
	}

	getTenantById(id): Observable<ITenant> {
		/* istanbul ignore else*/
		if (environment.mock) {
			return this.http.get<ITenant>(COMMONENDPOINTS.tenant_by_id.url);
		}
		return this.http.get<ITenant>(`${COMMONENDPOINTS.tenant_summary.url}/${id}`);
	}

	getImageById(id) {
		return this.http.get(`${this.configurationServiceUrl}/generic/image/${id}`, { observe: "response", responseType: "text" });
	}

	deleteTENANT(id: number | string) {
		return this.http.delete<any>(
			`${COMMONENDPOINTS.tenant_summary.url}/${id}`
		);
	}

	createTENANT(tenantRecord, icon) {
		return this.http.post(`${COMMONENDPOINTS.tenant_summary.url}`, tenantRecord);
	}

	editTENANT(tenantRecord, id, icon?) {
		return this.http.put(`${COMMONENDPOINTS.tenant_summary.url}/${id}`, tenantRecord);
	}
}
