import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { interval, Subscription } from "rxjs";
import { catchError, startWith, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { DATA_POOLING_INTERVALS } from "../config/data-pooling-interval.config";
import { TranslateService } from "./translate.service";

@Injectable()
export class SessionService {
	modalRef: BsModalRef;
	pollingSubscription: Subscription;

	constructor(
		private readonly http: HttpClient,
		private readonly modalService: BsModalService,
		private readonly translateService: TranslateService
	) { }

	initPolling() {
		if (environment.production) {
			this.pollingSubscription = interval(DATA_POOLING_INTERVALS.session).pipe(
				startWith(0),
				switchMap(() => this.ping())
			).subscribe((r: any) => {
				if (r.status === 302 || r.body === "login.login_hint_not_found" || r.url.endsWith("/login")) {
					this.pollingSubscription.unsubscribe();
					this.modalRef = this.modalService.show(ConfirmDialogComponent, { class: "confirm modal-sm", ignoreBackdropClick: true });
					this.modalRef.content.title = this.translateService.getText("CORE.SESSION:TIMEOUT_HEADING");
					this.modalRef.content.body = this.translateService.getText("CORE.SESSION:TIMEOUT_MSG");
					this.modalRef.content.showCancel = false;
					this.modalRef.content.showCloseButton = false;
					this.modalRef.content.onClose.subscribe(result => {
						if (result) {
							this.reloadPage();
						}
					});
				}
			}, e => this.errorHandler(e));
		}
	}

	ping() {
		const PROTOCOL = window.location.protocol;
		const HOST = window.location.host;
		return this.http.get(`${PROTOCOL}//${HOST}`, { responseType: "text", observe: "response" }).pipe(catchError(e => e));
	}

	errorHandler(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}`);
		}
	}

	reloadPage() {
		window.location.reload();
	}
}
