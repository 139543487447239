import { environment } from '../environments/environment';

export const config = {
	debug: false,
	handleErrors: true,
	commandPollingInterval: 5000,
	commandPollingIntervalApp: 800,
	pollingInterval500: 500,
	pollingInterval450: 450,
	pollingInterval50: 50,
	myprofileURl: `${environment.accountManagerAppUrl}/#/sso/myprofile`,
	singleSignOnURL: '/sso',
	syncPollingInterval: 10000,
	syncPollingCounter: 3
};
