import { Component, OnInit, Input, } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { TranslateService } from "src/app/modules/core/services/translate.service";

@Component({
	selector: "app-confirm-dialog",
	templateUrl: "./confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent implements OnInit {
	public onClose: Subject<boolean>;
	public title = "Delete?";
	public body = "this?";
	public showCancel = true;
	public showCloseButton = true;
	public showRadioSelection = false;
	public radioChoice = 'fullYear';
	public onRadioChoice: Subject<string>;
	constructor(
		private readonly _bsModalRef: BsModalRef,
		private readonly translateService: TranslateService,
	) { }

	public ngOnInit(): void {
		this.onClose = new Subject();
		this.onRadioChoice = new Subject();
	}

	public onConfirm(): void {
		this.onClose.next(true);
		this._bsModalRef.hide();
	}

	public onCancel(): void {
		this.onClose.next(false);
		this._bsModalRef.hide();
	}

	private onRadioSelectionChange = () => {
		this.onRadioChoice.next(this.radioChoice);
	}
}
