import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable()
export class AppLoaderService {
	public loaderStatus = false;
	constructor(private readonly loader: NgxUiLoaderService) { }

	getLoaderStatus(): boolean {
		return this.loaderStatus;
	}

	startLoader() {
		this.loader.start();
		this.loaderStatus = true;
	}

	stopLoader() {
		this.loader.stop();
		this.loaderStatus = false;
	}
}
