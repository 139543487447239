import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OneTrustCookieTypes, SiCookiePolicyService, SiCookiesService } from '@building-x/common-ui-ng';

import { config } from '../../../assets/app-config';

@Injectable()
export class Localization {

  constructor(private readonly translate: TranslateService, private readonly cookieService: SiCookiesService,
    @Inject(DOCUMENT) private readonly document: Document, private readonly cookiePolicy: SiCookiePolicyService) {
    this.cookieService.setDomain();
    this.translate.setDefaultLang('template');
    this.translate.onLangChange.subscribe(event => this.storeLang(event));
  }

  detectLanguage() {
    const browserLanguage = this.translate.getBrowserLang();
    const localLanguage = this.loadLang();
    const lang = localLanguage || browserLanguage as any;
    if (this.isTranslationFilePresent(lang)) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.getDefaultLang());
    }
    this.updateHTMLLang(lang);
  }

  setUserLanguage(language: string) {
    if (this.isTranslationFilePresent(language)) {
      this.translate.use(language);
    }
    this.updateHTMLLang(language);
  }

  storeLang(event: LangChangeEvent) {
    let retryCount = 0;
    const cookiePolicyRetry = setInterval(() => {
      retryCount++;
      if (
        this.cookiePolicy.permitted(OneTrustCookieTypes.Required) ||
        retryCount === 6
      ) {
        clearInterval(cookiePolicyRetry);
        if (this.cookiePolicy.permitted(OneTrustCookieTypes.Required)) {
          const cookieExpiry = new Date();
          cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
          // should not set template in cookie
          if (event.lang !== 'template') {
            this.cookieService.setCookie('lang', event.lang, '', cookieExpiry.toUTCString());
          }
        }
      }
    }, config.pollingInterval500);
  }

  updateHTMLLang(lang) {
    this.document.documentElement.lang = lang;
  }

  loadLang(): string {
    return this.cookieService.getCookie('lang') &&
      this.cookieService.getCookie('lang') !== 'undefined'
      ? this.cookieService.getCookie('lang')
      : '';
  }

  isTranslationFilePresent(lang): boolean {
    try {
      //eslint-disable-next-line
      const translationFile = require(`../../../assets/i18n/${lang}.json`);
      return !!translationFile;
    } catch (error) {
      return false;
    }
  }
}
