const EN = {
	COMMON: {
		"TEST:TEST1": "FEATURE 1",
		"ERROR_MESSAGE:GENERIC": "Something went wrong. Please try again later."
	},
	CORE: {
		// tslint:disable-next-line: max-line-length
		"CONFIGURATIONFACTORY:UNAUTHORIZED_MESSAGE": "You do not have the right permissions. Please contact the system administrator for support.",
		"CONFIGURATIONFACTORY:DEFAULT_ERROR_MESSAGE": "Failed to load the Page",
		"CONFIGURATIONFACTORY:DEFAULT_ERROR_BUTTON": "Refresh",
		"DROPDOWN:NO_ITEMS_FOUND_MESSAGE": "No items found",
		"HEADER:EDIT_BUTTON_TEXT": "Edit",
		"HEADER:CHANGE_ROLE_BUTTON_TEXT": "Change Role",
		"HEADER:CHANGE_LOCALE_TEXT": "Change Locale",
		"HEADER:ADMIN_LABEL": "Admin",
		"HEADER:SETTING_BUTTON_TEXT": "Setting",
		"HEADER:IMAGE_UPLOAD_SUCCESS_MESSAGE": "Image Upload Success!!",
		"HEADER:IMAGE_UPLOAD_ERROR_MESSAGE": "Please upload a JPEG,JPG or PNG file!!",
		"HEADER:CHANGE_ROLE_HEADER_TEXT": "Change Role",
		"HEADER:CHANGE_ROLE_DEFAULT_CHECKBOX_TEXT": "Make Default",
		"SESSION:TIMEOUT_HEADING": "Session Timed Out",
		"SESSION:TIMEOUT_MSG": "Session expired. Login to continue."
	},
	USER: {
		"LIVE_DASH:KPI_DELETION_SUCCESS": "FEATURE 1",
		"REPORTS_CONFIRM_DIALOG:HEADER": "Clear Report",
		"REPORTS_CONFIRM_DIALOG:MESSAGE1": "Are You Sure You Want To Clear ",
		"REPORTS_CONFIRM_DIALOG:MESSAGE2": "Report",
		"REPORTS:REPORT_UNAVAILABLE": "Report is unavailable",
		"REPORTS:NO_DATA_MESSAGE": "No reports available",
		"REPORTS:TABLE_NO_DATA_MESSAGE": "No records available for the applied filters",
		"REPORTS_MODAL_HEADER:VIEW_PARAMETER": "View Parameter",
		"REPORTS_MODAL_HEADER:VIEW_REPORT": "View Report",
		"REPORTS_MODAL_HEADER_BUTTON:ADD_NEW_PARAMETER": "Add New Request",
		"REPORTS_BUTTON:VIEW": "View",
		"REPORTS_BUTTON:DOWNLOAD": "Download",
		"REPORTS_BUTTON:CLEAR": "Clear",
		"REPORTS_ACTION_TABLE_COLUMN:LABEL": "Action",
		"REPORTS_DATA_SOURCE:LABEL": "Data Source",
		"REPORTS_PARAMETERS:SUBHEADER1": "Select the data source",
		"REPORTS_PARAMETERS:PLACEHOLDER1": "Select Data Source",
		"REPORTS_PARAMETERS:CREATION_SUCCESS": "Report request created successfully",
		"REPORTS_REPORT_CATEGORY:LABEL": "Category",
		"REPORTS_PARAMETERS:SUBHEADER2": "Select the catgeory to search by",
		"REPORTS_PARAMETERS:PLACEHOLDER2": "Select Category",
		"REPORTS_AVAILABLE_TILL:LABEL": "Available Till",
		"REPORTS_PARAMETERS:SUBHEADER3": "Select date till when report will be available",
		"REPORTS_NA:LABEL": "NA",
		"REPORTS_PARAMETERS:SUBHEADER4": "Provide a unique report name to identify by",
		"REPORTS_PARAMETERS:REPORTS_NAME:LABEL": "Report Name",
		"REPORTS_PARAMETERS:REPORTS_FIELD:REQUIRED": "{{field}} is required",
		"REPORTS_PARAMETERS:BUTTON:RESET": "Reset",
		"REPORTS_PARAMETERS:BUTTON:ADD_REQUEST": "Add Request",
		"REPORTS_PARAMETERS:PARAMETERS_HEADER": "Search Parameters",
		"REPORTS_PARAMETERS:PARAMETERS_SUBHEADER1": "Enter the desired parameters to initiate search",
		"REPORTS_PARAMETERS:PARAMETERS_SUBHEADER2": "Multiple entries per search box can be entered separated by commas.",
		"REPORTS_PARAMETERS:PARAMETERS_SUBHEADER3": "Fields marked with * are required",
		"REPORTS_VISUALIZE_RESULTS:BUTTON_HEADER_LABEL": "Visualize Results",
		"REPORTS_VISUALIZE_RESULTS:SUBHEADER-STEP1": "Select Chart Type",
		"REPORTS_VISUALIZE_RESULTS:SUBHEADER-STEP2": "Select Data",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:DISCARD": "Discard",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:NEXT": "Next",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:BACK": "Back",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:CLOSE": "Close",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:EDIT": "Edit",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:PRINT": "Print",
		"REPORTS_VISUALIZE_RESULTS:BUTTON:VISUALIZE": "Visualize",
		"REPORTS_VISUALIZE_RESULTS:PLACEHOLDER": "Choose Data",
		"REPORTS_VISUALIZE_RESULTS:XAXIS": "X-Axis",
		"REPORTS_VISUALIZE_RESULTS:YAXIS": "Y-Axis",
		"REPORTS_VISUALIZE_RESULTS:DISCLAIMER": "Please select proper columns to visualize the data on chart. Multiple Y axes can be chosen. Scaling will vary with each axis.",
		"REPORTS_VISUALIZE_RESULTS:DISCLAIMER_PIE": "Please select proper values to visualize the data on chart. Multiple values can be chosen.",
		"REPORTS_STATUS:IN_PROGRESS": "In Progress",
		"REPORTS_STATUS:AVAILABLE": "Available",
		"REPORTS_STATUS:ERROR": "Error",
		"REPORTS_TEXT:ALL": "All",
		"REPORTS_TEXT:MORE": "more",
		"REPORTS_FILTER_TEXT": "Filter",
		"REPORTS_FILTER_LEFT_TEXT": "Text",
		"REPORTS_FILTER_RIGHT_TEXT": "Dropdown",
		"HEADER:VIEW_DASHBOARD": "Dashboard",
		"HEADER:VIEW_DEEPANALYSIS": "Deep Analysis",
		"HEADER:VIEW_BENCHMARKING": "Benchmarking",
		"HEADER:VIEW_NOTIFICATION": "Notification",
		"HEADER:VIEW_CONTROL_PANEL": "Control Panel",
		"HEADER:VIEW_DIAGNOSTIC": "Diagnostic",
		"HEADER:VIEW_REPORTS": "Reports",
		"HEADER:VIEW_NAVIGATOR": "Navigator",
		"HEADER:VIEW_HISTORY": "Tracer",
		"HEADER:DROPDOWN_TEXT": "MODE",
		"BENCHMARKING:NO_SUB_LEVEL_MESSAGE": "No Sub Elements Available",
		"BENCHMARKING:DROPDOWN_PLACEHOLDER": "Select a KPI to benchmark",
		"BENCHMARKING:TAB_CUSTOM": "Custom",
		"BENCHMARKING:TAB_BEST_10": "Best 10",
		"BENCHMARKING:TAB_WORST_10": "Worst 10",
		"BENCHMARKING:SEARCH_PLACEHOLDER": "Search for a {{selectedElementTypeName}}",
		"BENCHMARKING:TAB_TITLE_1": "Benchmarking",
		"BENCHMARKING:TAB_TITLE_2": "Performers",
		"BENCHMARKING:TAB_MESSAGE_1": "{{selectedElementTypeName}} list not available for {{selectedKpiName}} KPI",
		"BENCHMARKING:TAB_MESSAGE_2": "{{selectedKpiName}} is not available at any {{selectedElementTypeName}}",
		"LIVE_DASHBOARD:LIBRARIES_METRICS_LAYOUT:TARGET": "Target",
		"LIVE_DASHBOARD:LIBRARIES_METRICS_LAYOUT:ACTUAL": "Actual",
		"LIVE_DASHBOARD:NO_DATA": "No Data",
		"FALCON-EYE_VIEW:NO_FLOOR_MESSAGE": "No floor exists for {{element}}",
		"FALCON-EYE_VIEW:NO_ROOM_MESSAGE": "No room exists for {{element}}",
		"DIAGNOSTIC:TAB_HEADER1": "Agent Monitoring",
		"DIAGNOSTIC:TAB_HEADER2": "Asset Hierarchy",
		"DIAGNOSTIC:WIDGET1": "Health - Overview",
		"DIAGNOSTIC:WIDGET2": "Connection Status",
		"DIAGNOSTIC:WIDGET3": "Data Source - Status",
		"DIAGNOSTIC:AGENT_LIST_HEADER": "Agent List",
		"DIAGNOSTIC:TABLE_HEADER1": "Agent Name",
		"DIAGNOSTIC:TABLE_HEADER2": "Protocol",
		"DIAGNOSTIC:TABLE_HEADER3": "Source",
		"DIAGNOSTIC:TABLE_HEADER4": "High Availability",
		"DIAGNOSTIC:TABLE_HEADER5": "Health Status",
		"DIAGNOSTIC:TABLE_HEADER6": "Connection Status",
		"DIAGNOSTIC:TABLE_HEADER7": "Data Source Status",
		"DIAGNOSTIC:TABLE_HEADER8": "Actions",
		"DIAGNOSTIC:FILTER_TEXT": "Filter by",
		"DIAGNOSTIC:SEARCH_TEXT": "Search",
		"DIAGNOSTIC:FILTER_ALL": "All",
		"DIAGNOSTIC:DETAILED_VIEW": "Detailed View",
		"DIAGNOSTIC:AGENT_TRENDS_HEADER": "Agent Trends",
		"DIAGNOSTIC:AGENT_TREND1": "Agent Health Status",
		"DIAGNOSTIC:AGENT_TREND1_LIVETEXT": "Live Status",
		"DIAGNOSTIC:AGENT_TREND1_TODAYTEXT": "Today's status",
		"DIAGNOSTIC:AGENT_TREND2": "Agent Host Virtual Machine CPU Usage",
		"DIAGNOSTIC:AGENT_TREND2_LIVETEXT": "Live Usage",
		"DIAGNOSTIC:AGENT_TREND2_TODAYTEXT": "Today's usage",
		"DIAGNOSTIC:AGENT_TREND3": "Agent Host Virtual Machine RAM Consumption",
		"DIAGNOSTIC:AGENT_TREND3_LIVETEXT": "Live Consumption",
		"DIAGNOSTIC:AGENT_TREND3_TODAYTEXT": "Today's Consumption",
		"DIAGNOSTIC:AGENT_TREND4": "Agent Virtual Machine Disk Space Available",
		"DIAGNOSTIC:AGENT_TREND4_LIVETEXT": "Live Availability",
		"DIAGNOSTIC:AGENT_TREND4_TODAYTEXT": "Today's Availabilty",
		"DIAGNOSTIC:DETAILED_PARAM1": "Agent Name",
		"DIAGNOSTIC:DETAILED_PARAM2": "Type",
		"DIAGNOSTIC:DETAILED_PARAM3": "Data Source Name",
		"DIAGNOSTIC:DETAILED_PARAM4": "Mindsphere Asset Name",
		"DIAGNOSTIC:DETAILED_PARAM5": "Interaction Protocol",
		"DIAGNOSTIC:DETAILED_PARAM6": "Scan Rate",
		"DIAGNOSTIC:DETAILED_PARAM7": "Is this primary ?",
		"DIAGNOSTIC:DETAILED_PARAM8": "Last Heartbeat",
		"DIAGNOSTIC:DETAILED_PARAM9": "Agent Virtual Machine IP",
		"DIAGNOSTIC:DETAILED_PARAM10": "Agent Host Virtual Machine Name",
		"DIAGNOSTIC:DETAILED_PARAM11": "Agent Host Virtual Machine Port",
		"DIAGNOSTIC:DETAILED_PARAM12": "Agent Status",
		"DIAGNOSTIC:DETAILED_PARAM13": "Source Status",
		"DIAGNOSTIC:STATUS_ONLINE": "Online",
		"DIAGNOSTIC:STATUS_OFFLINE": "Offline",
		"DIAGNOSTIC:STATUS_ON": "On",
		"DIAGNOSTIC:STATUS_OFF": "Off",
		"DIAGNOSTIC:STATUS_HEALTHY": "Healthy",
		"DIAGNOSTIC:STATUS_AVERAGE": "Average",
		"DIAGNOSTIC:STATUS_CRITICAL": "Critical",
		"DIAGNOSTIC:ASSET_HIERARCHY_LOADING_MESSAGE": "Loading",
		"NOTIFICATION:NO_RESULTS_LIVE": "No live events found.",
		"NOTIFICATION:NO_RESULTS_ARCHIVED": "No archived events found.",
		"DATEPICKER:DAILY_ERROR_MESSAGE": "Range must be 30 days apart",
		"DATEPICKER:WEEKLY_ERROR_MESSAGE": "Range must be 7 days apart or multiples of 7",
		"DATEPICKER:MONTHLY_ERROR_MESSAGE": "Range must be 30 days apart",
		"DATEPICKER:YEARLY_ERROR_MESSAGE": "Range should be 12 months and should be start and end dates of month",
		"DATEPICKER:BUTTON_LABEL_CLEAR": "Clear",
		"DATEPICKER:BUTTON_LABEL_CANCEL": "Cancel",
		"DATEPICKER:BUTTON_LABEL_APPLY": "Apply",
		"DEEP_ANALYSIS:ELEMENT_TYPE_HEADER": "Select Element Type",
		"DEEP_ANALYSIS:ELEMENTS_HEADER": "Select Element",
		"DEEP_ANALYSIS:FUTURE_DATE_TYPE_HEADER": "Select Future KPIs",
		"DEEP_ANALYSIS:USECASE_HEADER": "Use Case",
		"DEEP_ANALYSIS:KPI_DROPDOWN_PLACEHOLDER": "Search and add KPIs",
		"DEEP_ANALYSIS:DELETE_BUTTON": "Delete",
		"DEEP_ANALYSIS_TEXT:ALL": "All",
		"DEEP_ANALYSIS_TEXT:MORE": "more",
		"DEEP_HISTORY:THRESHOLD_TITLE": "Threshold",
		"DEEP_HISTORY:AGGREGATION_RANGE_TITLE": "Aggregation Range",
		"DEEP_HISTORY:CHART_TYPE_TITLE": "Chart Type",
		"DEEP_HISTORY:MERGE_CHARTS_TITLE": "Merge Charts",
		"DEEP_HISTORY:ERROR_MESSAGE": "There might be more data. Change interval/ date range.",
		"HISTORY_MODAL:HEADING": "History View",
		"CONTROL_PANEL:NORESULTS": "No control points found.",
		"CONTROL_PANEL:CANCEL_ALL": "Cancel All",
		"CONTROL_PANEL:CONTROL_GROUPS": "Control Groups",
		"CONTROL_PANEL:TIME:LAST_UPDATED": "Last Updated",
		"CONTROL_PANEL:TIME:JUST_NOW": "Just now",
		"CONTROL_PANEL:TIME:MIN_AGO": "1 Min ago",
		"CONTROL_PANEL:TIME:MINS_AGO": "{{value}} Mins ago",
		"CONTROL_PANEL:LOADING_SWITCH_ON": "Switching On",
		"CONTROL_PANEL:LOADING_SWITCH_OFF": "Switching Off",
		"CONTROL_PANEL:LOADING_SLIDER": "Setting Value",
		"CONTROL_PANEL:LOADING_CANCEL_ALL": "Cancelling All",
		"CONTROL_PANEL:COMMAND_ERROR": "Command Execution failed",
		"CONTROL_PANEL:CANCEL_ALL_MESSAGE": "Cancels only currently running program if any. Programs that is yet to start will continue as scheduled.",
		"NAVIGATOR:TAB_HEADER1": "ENERGY ANALYTICS",
		"NAVIGATOR:TAB_HEADER2": "BUILDING HEALTH",
		"NAVIGATOR:TAB_HEADER3": "FAULT SUMMARY",
		"NAVIGATOR:TAB_HEADER4": "FAULT ANALYSIS"
	},
	ADMIN: {
		"COMMON:RESET": "Reset",
		"COMMON:SAVE": "Save",
		"COMMON:CONFIRM_OK": "Ok",
		"COMMON:CONFIRM_CANCEL": "Cancel",
		"COMMON:DISCARD": "Discard",
		"KPI:DELETION_SUCCESS": "FEATURE 1",
		"KPI:CREATION_SUCCESS": "FEATURE 1",
		"USECASE:DELETION_SUCCESS": "FEATURE 1",
		"CONTROLPOINT:REQUIRED_ERROR_MESSAGE": "{{parameter}} is required",
		"CONTROLPOINT:RANGE_ERROR_MESSAGE": "{{limit}} Value to be entered should be {{value}}",
		"CONTROLGROUP:DELETION_SUCCESS": "",
		"CONTROLGROUP:DELETION_FAILURE": "",
		"CONTROLGROUP:ERROR_CGNAME": "Control Group name is required",
		"CITYPROFILE_TAB1:TITLE": "City Profile Creation",
		"CITYPROFILE_TAB2:TITLE": "GIS Mapping",
		"CITYPROFILE_TAB3:TITLE": "3D Mapping",
		"CITYPROFILE_TAB4:TITLE": "KPI Mapping",
		"CITYPROFILE_TAB5:TITLE": "Control Point Mapping",
		"CITYPROFILE_TAB1:CPNAME": "City Profile Name",
		"CITYPROFILE_TAB1:ROOT_CONFIG_TITLE": "Root Configuration",
		"CITYPROFILE_TAB1:ROOT_CONFIG_SUBTITLE": "Select the required permission",
		"CITYPROFILE_TAB1:LEVELS_CONFIG_TITLE": "Levels Configuration",
		"CITYPROFILE_TAB1:LEVELS_CONFIG_SUBTITLE": "Configure the required levels",
		"CITYPROFILE_TAB1:CONFIG_SITE": "Configure for Site",
		"CITYPROFILE_TAB1:EDIT_ELEMENT": "Edit Element",
		"CITYPROFILE_TAB1:NODE_NAME": "Node Name",
		"CITYPROFILE_TAB1:LEVEL_TYPE": "Level Type",
		"CITYPROFILE_TAB1:SUBLEVELS": "Sublevels",
		"CITYPROFILE_TAB1:ADD_SUB_LEVEL": "Add Sub Level",
		"CITYPROFILE_TAB1:DELETE": "Delete",
		"CITYPROFILE_TAB1:SUBMIT": "Edit Profile",
		"CITYPROFILE_TAB2:REF_GIS": "Reference GIS",
		"CITYPROFILE_TAB2:UPLOAD_GIS": "Upload GIS",
		"CITYPROFILE_TAB2:PICK_TITLE": "Pick the region in the GIS to be mapped to",
		"CITYPROFILE_TAB4:ADD_KPI": "Please Add A KPI To Get Started",
		"KPI_MAPPING:ADD_NEW_PARAM": "Add New Parameter",
		"KPI_MAPPING:REMOVE_PARAM": "Remove KPI",
		"KPI_MAPPING:DISCARD_PARAM": "Discard KPI",
		"KPI_MAPPING:CONFIRM_REMOVE_PARAM_BODY": "Confirm to remove mapping ?",
		"KPI_MAPPING:CONFIRM_DISCARD_PARAM_BODY": "Confirm to discard changes ?",
		"KPI_MAPPING:CONFIRM_REMOVE_PARAM_HEADER": "Remove KPI Mapping",
		"KPI_MAPPING:FETCHING_DATA": "Fetching Data..Please Wait..",
		"KPI_MAPPING:AGG_VALUE_FS": "Aggregate values from sub levels?",
		"KPI_MAPPING:KPI_TYPE": "KPI Type",
		"KPI_MAPPING:ASSET_VARIABLE": "Asset Variable",
		"KPI_MAPPING:NO_THRESHOLD": "No Thresholds Found",
		"KPI_MAPPING:THRESHOLD": "Threshold",
		"KPI_MAPPING:MESSAGE": "Message",
		"KPI_MAPPING:FROM": "From",
		"KPI_MAPPING:TO": "To",
		"KPI_MAPPING:YES": "Yes",
		"KPI_MAPPING:NO": "No",
		"KPI_MAPPING:DISCARD": "Exit",
		"KPI_MAPPING:MAP_CP": "Map Control Point",
		"KPI_MAPPING:COND1": "From should be less than To",
		"KPI_MAPPING:COND2": "There should be only 0.01 point gap between slots for the thresholds",
		"KPI_MAPPING:COND3": "Threshold slots should not be overlapping",
		"KPI_MAPPING:ERROR_TEXT": "Something went wrong..",
		"RULE_ENGINE:RECIPIENT_CREATED_SUCCESS_MSG": "Recipient Group created successfully",
		"RULE_ENGINE:RECIPIENT_UPDATED_SUCCESS_MSG": "Recipient Group updated successfully"
	}
};
const DE = {
	COMMON: {
		"TEST:TEST1": "TEST1 IN GERMAN"
	},
	USER: {
		"LIVE_DASH:KPI_DELETION_SUCCESS": "KPI DELETION SUCCESS IN GERMAN"
	},
	ADMIN: {
		"KPI:DELETION_SUCCESS": "DELETION SUCCESS IN GERMAN",
		"KPI:CREATION_SUCCESS": "CREATION SUCCESS IN GERMAN",
		"USECASE:DELETION_SUCCESS": "DELETION SUCCESS IN GERMAN",
		"CONTROLGROUP:DELETION_SUCCESS": "IN GERMAN",
		"CONTROLGROUP:DELETION_FAILURE": "IN GERMAN",
		"CONTROLGROUP:ERROR_CGNAME": "Control Group name is required IN GERMAN"
	}
};

const UA = {
	TITLE: "مرحبا بكم في التطبيق",
	FEATURE: "ميزة 1"
};
export default function getTranslateData(language = "en-US") {
	switch (language) {
		case "de":
			return DE;
		case "ua":
			return UA;
		default:
			return EN;
	}
}
