import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "../../core/services/translate.service";

@Pipe({
	name: "translate",
	pure: false
})
export class TranslatePipe implements PipeTransform {

	constructor(private readonly translate: TranslateService) { }

	transform(key: any, value?: any): any {
		return this.translate.getText(key, value);
	}

}
