import { AuthService } from "../services/auth.service";
import { AppConfigService } from "../services/app-config.service";
import { errorHandling } from "./error-handling";
import { AppLoaderService } from "../services/app-loader.service";
import { ENDPOINTS } from "./../services/endpoints";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "../services/translate.service";

export function configurationFactory(
	auth: AuthService,
	appConfig: AppConfigService,
	configDeps: (() => () => {})[],
	appLoaderService: AppLoaderService,
	translateService: TranslateService
): () => Promise<any> {
	return (): Promise<any> => {
		appLoaderService.startLoader();
		return new Promise((resolve, reject) => {
			Promise.all([
				// auth.authInitializer(),
				// 	appConfig.thresholdListInitializer(),
				// 	appConfig.getAggregationTypesInitializer(),
				// 	appConfig.getSortTypesInitializer(),
				// 	appConfig.getThresholdIntervalsInitializer(),
				// 	appConfig.getVisualizationInitializer(),
				appConfig.getAppConfigInitializer(),
				// 	appConfig.getSwitchTypesInitializer()
			]).then(() => {
				resolve({});
				appLoaderService.stopLoader();
			}).catch((e: HttpErrorResponse) => {
				let message = "";
				reject(e);
				if (e.url.includes(ENDPOINTS.user_logged_in.url) || e.status === 0) {
					message = translateService.getText("CORE.CONFIGURATIONFACTORY:UNAUTHORIZED_MESSAGE");
				} else {
					message = translateService.getText("CORE.CONFIGURATIONFACTORY:DEFAULT_ERROR_MESSAGE");
				}
				appLoaderService.stopLoader();
				errorHandling(e, translateService.getText("CORE.CONFIGURATIONFACTORY:DEFAULT_ERROR_BUTTON"), message);
			});
			resolve({});
		});
	};
}


