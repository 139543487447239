<div class="modal-header confirm">
	<h4 class="modal-title confirm" id="modal-title">{{title}}</h4>
	<button type="button" class="btn btn-circle btn-sm btn-ghost element-state-close" aria-describedby="modal-title"
		(click)="onCancel()" *ngIf="showCloseButton">
	</button>
</div>
<div class="modal-body confirm">
	<p><strong>{{body}}</strong></p>
	<div class="radio-container" *ngIf="showRadioSelection">
		<div class="form-check">
			<input type="radio" id="custom-radio-1" name="customRadio" class="form-check-input" value="fullYear"
				[(ngModel)]="radioChoice" (ngModelChange)="onRadioSelectionChange()" />
			<label class="form-check-label" for="custom-radio-1">Current Year</label>
		</div>
		<div class="form-check">
			<input type="radio" id="custom-radio-2" name="customRadio" class="form-check-input" value="completeMapping"
				[(ngModel)]="radioChoice" (ngModelChange)="onRadioSelectionChange()" />
			<label class="form-check-label" for="custom-radio-2">Complete Mapping</label>
		</div>
	</div>
</div>
<div class="modal-footer confirm">
	<button type="button" class="btn btn-secondary" (click)="onCancel()"
		*ngIf="showCancel">{{'ADMIN.COMMON:CONFIRM_CANCEL' | translate}}</button>
	<button type="button" class="btn btn-danger" (click)="onConfirm()">{{'ADMIN.COMMON:CONFIRM_OK' |
		translate}}</button>
</div>