<si-login-page data-cy="login-page"
  [heading]="'Portfolio Manager'" [warningText]="'LOGIN.WARNING_TEXT' | translate"
  [availableLanguages]="availableLanguages">
</si-login-page>

<!-- <si-landing-page
  heading="Portfolio Manager"
  [subtitle]=""
  [links]="[
    {title: 'Corporate information', href: 'http://www.siemens.com/corporate-information'},
    {title: 'Privacy policy', href: 'http://www.siemens.com/privacy-notice'},
    {title: 'Terms of use', href: 'http://www.siemens.com/terms-of-use'}
  ]"
  >

  <button type="submit" class="btn btn-primary w-100" routerLink="/user">
    {{ 'APP.LOGIN' | translate }}
  </button>
</si-landing-page> -->
