import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class PermissionService {
	constructor(private readonly authService: AuthService) { }

	hasPermission(permissionCode: string) {
		let flag = false;
		if (this.authService.getActiveRole()?.listOfScopes) {
			for (const scope of this.authService.getActiveRole()?.listOfScopes) {
				if (scope.code === permissionCode) {
					flag = true;
					break;
				}
			}
		}
		return flag;
	}

	hasPermissionAcrossRoles(permissionCode: string) {
		let flag = false;
		if (this.authService.getUser()?.roles) {
			for (const role of this.authService.getUser()?.roles) {
				for (const scope of role?.listOfScopes) {
					if (scope.code === permissionCode) {
						flag = true;
						break;
					}
				}
				if (flag) {
					break;
				}
			}
		}
		return flag;
	}

	hasPermissionCategory(permissionCategory: string) {
		let flag = false;
		if (!this.authService.getActiveRole()) {
			return true;
		}
		if (this.authService.getActiveRole()?.listOfScopes) {
			for (const scope of this.authService.getActiveRole()?.listOfScopes) {
				if (scope.category === permissionCategory) {
					flag = true;
					break;
				}
			}
		}
		return flag;
	}
}
