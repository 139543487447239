import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SiThemeService, ThemeType } from '@simpl/element-ng';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsService {
	readonly availableLanguages = ['en', 'de'];
	themeSubject = new BehaviorSubject<any>(null);
	public onThemeChange = this.themeSubject.asObservable();
	protected initialized$ = new ReplaySubject<boolean>(1);

	constructor(
		private translate: TranslateService,
		private themeService: SiThemeService
	) {
		translate.setDefaultLang('en');
		translate.onLangChange.subscribe(this.storeLanguage);

		const language = this.loadLanguage() ?? translate.getBrowserLang() ?? 'en';
		this.setLanguage(language);

		this.setTheme(this.loadTheme() ?? 'dark');
	}

	get language() {
		return this.translate.currentLang;
	}

	get theme() {
		return this.loadTheme() ?? 'dark';
	}

	setLanguage(language: string) {
		this.translate.use(language);
	}

	setTheme(theme: ThemeType) {
		this.themeService.applyThemeType(theme);
		this.storeTheme(theme);
	}

	private storeLanguage(event: LangChangeEvent) {
		localStorage.setItem('lang', event.lang);
	}

	private storeTheme(theme: ThemeType) {
		localStorage.setItem('theme', theme);
	}

	private loadLanguage() {
		return localStorage.getItem('lang');
	}

	private loadTheme() {
		return localStorage.getItem('theme') as ThemeType | undefined;
	}

	setThemeThroughNavbar(theme: any) {
		this.themeSubject.next(theme);
		this.storeTheme(theme);
		this.initialized$.next(true);
	}
}
