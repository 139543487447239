import { Injectable } from "@angular/core";
import { IProcessPromise } from "../interfaces/web-worker/web-worker.interface";

import { WORKERS } from "../config/worker.config";
import Logger from "./logger-service/logger.service";

@Injectable({
	providedIn: "root"
})
export class WebWorkerService {
	worker: Worker;
	processPromises: IProcessPromise[] = [];

	constructor() { }

	createWorker() {
		/* istanbul ignore else*/
		if (typeof Worker !== "undefined") {
			// Create a new worker
			this.worker = new Worker("./../../../app.worker", { type: "module" });
			this.worker.onmessage = ({ data }) => {
				Logger.info(`Webworker Service: got message: ${data}`);
				if (data.name === "ERROR") {
					Logger.error(data.result);
				} else {
					this.reolvePromise(data.name, data.result);
				}
			};
		}
	}

	reolvePromise(name, result) {
		let promiseToBeResolved;
		let index = 0;
		this.processPromises.forEach((promise, i) => {
			/* istanbul ignore else*/
			if (promise.name === name) {
				promiseToBeResolved = promise;
				index = i;
			}
		});
		this.processPromises.splice(index, 0);
		promiseToBeResolved.promiseObj.resolve(result);
	}

	startBackgroundProcess(processName: string, args) {
		const promiseObj = {
			resolve: {},
			reject: {}
		};
		const res = new Promise((resolve, reject) => {
			promiseObj.resolve = resolve;
			promiseObj.reject = reject;
		});
		this.processPromises.push({ name: processName, promiseObj });
		if (this.worker) {
			this.worker.postMessage({ processName, args });
		} else {
			// import(`./backgroundProcesses/${processName}`).then(mod => {
			// 	const processClass = mod[WORKERS[processName]];
			// 	const processInstance = new processClass();
			// 	const result = processInstance.processData(args);
			// 	this.reolvePromise(processName, result);
			// });
		}
		return res;
	}

	stopWorker() {
		this.worker.terminate();
		this.worker = null;
		this.processPromises = [];
	}
}
