import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@building-x/common-ui-ng';
import { Observable, from as observableFrom, ReplaySubject, BehaviorSubject, map, filter, Subscription } from 'rxjs';
import { config } from '../../assets/app-config';
import { environment } from '../../environments/environment';
import { AuthService } from '../modules/core/services/auth.service';
// import { UserService } from "../modules/admin/user-management/services/user.service";
export interface AccountInfo {
	title?: string;
	image?: string;
}

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	protected initialized$ = new ReplaySubject<boolean>(1);
	private signSignOnUrl = config.singleSignOnURL;
	private readonly whiteListURLs = ['login', 'logout'];
	roleType: string
	// userService: UserService;
	authService: AuthService;
	identityClaimsSubject = new BehaviorSubject<any>(null);
	public onIdentityClaims = this.identityClaimsSubject.asObservable();
	private accessToken = "";
	constructor(
		private routerObj: Router,
		@Inject(AuthenticationService) private readonly authenticationService: AuthenticationService,
		injector: Injector
	) {
		this.authService = injector.get<AuthService>(AuthService);
	}

	initialize(): void {
		const redirectUrl = document.head.getElementsByTagName('base')[0].href;
		observableFrom(
			this.authenticationService.init(
				environment.clientId,
				environment.issuer,
				'#/login',
				redirectUrl,
				environment.audience,
				environment.siemensIdBaseUrl
			)
		).subscribe(async () => {
			this.setURLParams();
			this.accessToken = this.authenticationService.idToken;
			// added for session checking.
			// this.authenticationService.initSSOLogin().subscribe();
			if (this.authenticationService.isAuthenticated()) {
				const identityClaims = this.authenticationService.identityClaims;
				// let identity = { identityClaims: identityClaims, roledata: roledata };
				try {
					await this.authService.authInitializer(identityClaims?.email);
				} catch (error) {
					console.log("error", error);
				}
				// if (this.routerObj.url == "/" || this.routerObj.url == "/landing") { // commenting this as its rerouting to live-ashboard always
				if (this.routerObj.url == "/landing") {
					this.routerObj.navigate(['/user']);
				}
				this.identityClaimsSubject.next(identityClaims);
				this.initialized$.next(true);

			} else if (window.location.href.toString().includes(this.signSignOnUrl)) {
				this.handleSSO();
			} else {
				this.initialized$.next(false);
			}
		}, (error) => {
			console.log("Error", error);
			// if (error == 'Token has expired') {
			// 	this.routerObj.navigate(['/logout']);
			// }
		});
	}

	get initialized(): Observable<boolean> {
		return this.initialized$.asObservable();
	}


	requireLogin(): Observable<true> {
		return this.initialized.pipe(
			map<boolean, true>(signedIn => {
				if (!signedIn) {
					throw new Error('User not authenticated');
				}
				return signedIn;
			})
		);
	}

	get authToken(): Observable<string> {
		return this.requireLogin().pipe(
			map(() => this.authenticationService.accessToken)
		);
	}

	get accountInfo(): Observable<AccountInfo> {
		return this.requireLogin().pipe(
			map(() => ({
				title: this.authenticationService.identityClaims.name,
				image: this.authenticationService.identityClaims.picture
			}))
		);
	}

	login(): Observable<void> {
		return this.initialized.pipe(
			map(() => this.authenticationService.signIn())
		);
	}

	logout(): Observable<void> {
		return this.initialized.pipe(
			map(() => this.authenticationService.signOut())
		);
	}

	private handleSSO() {
		this.authenticationService.loginSSO().subscribe(res => {
			if (res) {
				// logic to sign in user
				this.initialized.pipe(
					filter(signedIn => !!signedIn)).subscribe();
				this.initialized$.next(true);
				this.navigateToParam();
			}
		});
	}

	private setURLParams() {
		let param = window.location.hash;
		if (param.length > 1 && !this.whiteListURLs.find(ele => param.includes(`#/${ele}`))) {
			if (param.includes(`#${this.signSignOnUrl}`)) {
				param = param.replace(`#${this.signSignOnUrl}`, '');
			} else if (param.startsWith('#/')) {
				param = param.substring(2);
			}
			sessionStorage.setItem('entryPointUrl', param);
		}
	}


	private navigateToParam() {
		const param = sessionStorage.getItem('entryPointUrl');
		if (param) {
			sessionStorage.removeItem('entryPointUrl');
			this.routerObj.navigateByUrl(param);
		} else {
			this.routerObj.navigate(['/']);
		}

	}

	getAccessToken = () => {
		return this.accessToken;
	}
}

@Injectable({
	providedIn: 'root'
})
export class MockSessionService extends SessionService {
	override initialize(): void {
		this.initialized$.next(true);
	}
}
