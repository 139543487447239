import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { API_BASE_URLS } from "../config/api.config";
import { WebWorkerService } from "./web-worker.service";

@Injectable()
export class CoreService {

	private readonly hostUrl = environment.baseUrl;
	private readonly serviceUrls = API_BASE_URLS;

	constructor(private readonly webWorkerService: WebWorkerService) {
	}

	getRulesServiceUrl() {
		return `${this.hostUrl}/${this.serviceUrls.ruleService}`;
	}

	getConfigurationServiceUrl() {
		return `${this.hostUrl}/${this.serviceUrls.configurationService}`;
	}

	getAgregationServiceUrl() {
		return `${this.hostUrl}/${this.serviceUrls.aggregationService}`;
	}

	getMiscellaneousServiceUrl() {
		return `${this.hostUrl}/${this.serviceUrls.miscellaneousService}`;
	}

	getDiagnosticServiceUrl() {
		return `${this.hostUrl}/${this.serviceUrls.diagnosticService}`;
	}

	getDetailedSeachUrl() {
		return `${this.hostUrl}/${this.serviceUrls.detailedSearchService}`;
	}

	getImageUrl(imageId: string) {
		let url: string;
		if (!environment.mock && imageId) {
			url = `${this.hostUrl}/${this.serviceUrls.configurationService}/generic/image/${imageId}`;
		} else {
			url = imageId;
		}
		return url;
	}
}
